import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs/FullScreen/Dialog";
import { WorkerBanners } from "@src/appV2/OpenShifts/Banners/WorkerBanners";
import { eachMonthOfInterval, format, isPast, isToday, max, startOfMonth } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { DateCell } from "../Calendar/DateCell";
import { DateCellAvailabilityInfo } from "../Calendar/DateCellAvailabilityInfo";
import { CalendarGridMonth } from "../Calendar/GridMonth";
import { DialogFooter } from "../components/DialogFooter";
import { useShiftDiscoveryCalendarDataContext } from "../contexts/useShiftDiscoveryCalendarData";
import { SHIFT_DISCOVERY_LIST_PATH } from "../paths";
import { ShiftDiscoveryCalendarPickerHeader } from "./Header";

interface ShiftDiscoveryCalendarPickerProps {
  modalState: UseModalState;
}

export function ShiftDiscoveryCalendarPicker(props: ShiftDiscoveryCalendarPickerProps) {
  const { modalState } = props;

  const history = useHistory();

  const {
    workerShiftsByDate,
    openShiftsCountData,
    dateRange,
    openShiftsCountIsLoading,
    workerShiftsIsLoading,
  } = useShiftDiscoveryCalendarDataContext();

  const { startDate, endDate } = dateRange;

  const monthDates = eachMonthOfInterval({ start: startDate, end: endDate }).map((monthDate) =>
    max([startDate, startOfMonth(monthDate)])
  );

  return (
    <FullScreenDialog hideBackdrop modalState={modalState}>
      <DialogContent
        sx={(theme) => ({
          padding: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          backgroundColor: theme.palette.background.tertiary,
        })}
      >
        <ShiftDiscoveryCalendarPickerHeader />

        <Stack sx={{ px: 5, py: 7, overflow: "auto" }} gap={7}>
          {monthDates.map((monthDate) => (
            <CalendarGridMonth
              key={monthDate.toISOString()}
              startDate={monthDate}
              renderDateCell={(date) => {
                const formattedDate = format(date, "yyyy-MM-dd");
                const isDateBooked = isDefined(workerShiftsByDate?.[formattedDate]);
                const isDateAvailable = isDefined(openShiftsCountData?.[formattedDate]);
                const isInPast = isPast(date) && !isToday(date);
                const isLoading = openShiftsCountIsLoading || workerShiftsIsLoading;

                return (
                  <DateCell
                    date={date}
                    size="small"
                    isLoading={isLoading}
                    disabled={isInPast}
                    onToggleSelect={() => {
                      history.push(
                        generatePath(DeprecatedGlobalAppV1Paths.OPEN_SHIFT_DAY_VIEW, {
                          date: formattedDate,
                        })
                      );
                    }}
                  >
                    <DateCellAvailabilityInfo
                      isDateBooked={isDateBooked}
                      isDateAvailable={isDateAvailable}
                    />
                  </DateCell>
                );
              }}
              renderPastCell={(date) => <DateCell disabled date={date} size="small" />}
            />
          ))}
        </Stack>

        <Stack spacing={1}>
          <WorkerBanners />
        </Stack>

        <DialogFooter
          orientation="horizontal"
          sx={(theme) => ({
            boxShadow: theme.shadows[3],
            backgroundColor: theme.palette.background.tertiary,
          })}
          onClose={() => {
            history.push(`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`);
          }}
        />
      </DialogContent>
    </FullScreenDialog>
  );
}
