import { Box, Stack } from "@mui/material";

import { CbhIcon } from "../components/CbhIcon";

interface DateCellAvailabilityInfoProps {
  isDateBooked?: boolean;
  isDateAvailable?: boolean;
  disabled?: boolean;
}

export function DateCellAvailabilityInfo(props: DateCellAvailabilityInfoProps) {
  const { isDateBooked, isDateAvailable, disabled } = props;

  return (
    <Stack direction="row" alignItems="center" height="1rem">
      {isDateBooked && (
        <Box
          sx={(theme) => ({
            color: disabled ? theme.palette.disabled?.primary : theme.palette.success.main,
            display: "flex",
          })}
        >
          <CbhIcon type="checkmark" size="small" />
        </Box>
      )}

      {isDateAvailable && (
        <Box
          sx={(theme) => ({
            backgroundColor: disabled
              ? theme.palette.disabled?.primary
              : theme.palette.primary.main,
            borderRadius: "50%",
            width: "0.375rem",
            height: "0.375rem",
          })}
        />
      )}
    </Stack>
  );
}
