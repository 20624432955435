import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type BoxProps } from "@mui/material";

export interface CalendarGridSectionWrapperProps extends BoxProps {}

export function CalendarGridSectionWrapper(props: CalendarGridSectionWrapperProps) {
  const { sx, ...restProps } = props;

  return (
    <Box
      sx={mergeSxProps(
        {
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          rowGap: 3,
        },
        sx
      )}
      {...restProps}
    />
  );
}
