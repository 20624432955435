import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { eachDayOfInterval, endOfMonth, format, startOfMonth, subDays } from "date-fns";

import { CalendarGridSectionWrapper } from "./GridSectionWrapper";

interface CalendarGridMonthProps {
  /**
   * The start date of the month to render
   * All days before this date will be rendered via `renderEmptyCell`
   */
  startDate: Date;
  renderDateCell: (date: Date) => React.ReactNode;
  /**
   * Used to render empty cells at the start of the month to align with Sunday start
   * Renders invisible cell if not provided
   */
  renderEmptyCell?: (date: Date) => React.ReactNode;
  /**
   * Used to render past dates (earlier in the month than `startDate`)
   * Renders invisible cell if not provided
   */
  renderPastCell?: (date: Date) => React.ReactNode;
}

export function CalendarGridMonth(props: CalendarGridMonthProps) {
  const { startDate, renderDateCell, renderEmptyCell, renderPastCell } = props;

  const monthStart = startOfMonth(startDate);
  const monthEnd = endOfMonth(startDate);

  const allMonthDays = eachDayOfInterval({
    start: monthStart,
    end: monthEnd,
  });

  // empty cells are needed to align with Sunday start
  const emptyCellsNeeded = monthStart.getDay();
  const emptyStartCells = Array.from({ length: emptyCellsNeeded }, (_, index) =>
    subDays(monthStart, emptyCellsNeeded - index)
  );

  return (
    <Stack spacing={5}>
      <Text variant="h5">{format(startDate, "MMM")}</Text>

      <CalendarGridSectionWrapper>
        {emptyStartCells.map((date) => (
          <Box key={date.toISOString()} sx={{ display: "flex", justifyContent: "center" }}>
            {renderEmptyCell ? renderEmptyCell(date) : null}
          </Box>
        ))}

        {allMonthDays.map((date) => {
          const isPastDate = date < startDate;

          if (isPastDate) {
            return (
              <Box key={date.toISOString()} sx={{ display: "flex", justifyContent: "center" }}>
                {renderPastCell ? renderPastCell(date) : null}
              </Box>
            );
          }

          return (
            <Box key={date.toISOString()} sx={{ display: "flex", justifyContent: "center" }}>
              {renderDateCell(date)}
            </Box>
          );
        })}
      </CalendarGridSectionWrapper>
    </Stack>
  );
}
