import { ButtonBase, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useHistory } from "react-router-dom";

import { CbhIcon } from "../components/CbhIcon";
import { HorizontalScrollView } from "../components/HorizontalScrollView";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "../paths";
import { ShiftInvitesCallout } from "../Shift/Invite/Callout";
import { UrgentShiftsCallout } from "../Shift/Urgent/Callout";
import { DATE_CELL_MEDIUM_HEIGHT, DATE_CELL_MEDIUM_WIDTH } from "../theming/components";

export function ShiftDiscoveryListViewHeader() {
  const history = useHistory();

  return (
    <Stack spacing={4} sx={{ paddingTop: 1, paddingBottom: 4 }}>
      <Stack direction="row" spacing={1}>
        <ButtonBase
          sx={{
            width: DATE_CELL_MEDIUM_WIDTH,
            height: DATE_CELL_MEDIUM_HEIGHT,
            backgroundColor: "transparent",
          }}
          onClick={() => {
            history.push(`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_CALENDAR_PATH}`);
          }}
        >
          <CbhIcon type="calendar-navigation" size="large" />
        </ButtonBase>
      </Stack>

      <HorizontalScrollView stretch sx={{ px: 5, alignSelf: "stretch" }}>
        <UrgentShiftsCallout />
        <ShiftInvitesCallout />
      </HorizontalScrollView>
    </Stack>
  );
}
