import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { type TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, Skeleton } from "@mui/material";
import { getDate } from "date-fns";

import { CbhIcon } from "../components/CbhIcon";
import {
  DATE_CELL_MEDIUM_HEIGHT,
  DATE_CELL_MEDIUM_WIDTH,
  DATE_CELL_SMALL_HEIGHT,
  DATE_CELL_SMALL_WIDTH,
} from "../theming/components";
import { formatDayName } from "../utils/formatDayName";

export interface DateCellProps {
  date: Date;
  size?: "small" | "medium";
  isSelected?: boolean;
  isLoading?: boolean;
  onToggleSelect?: (isSelected: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SIZE_CONFIGS = {
  small: {
    width: DATE_CELL_SMALL_WIDTH,
    height: DATE_CELL_SMALL_HEIGHT,
    paddingTop: 5,
    paddingBottom: 3,
    dayOfMonthVariant: "body1" as TextVariant,
    showDayName: false,
    showCloseButton: false,
  },
  medium: {
    width: DATE_CELL_MEDIUM_WIDTH,
    height: DATE_CELL_MEDIUM_HEIGHT,
    paddingTop: 4,
    paddingBottom: 3,
    dayOfMonthVariant: "h5" as TextVariant,
    showDayName: true,
    showCloseButton: true,
  },
} as const;

export function DateCell(props: DateCellProps) {
  const {
    date,
    size = "medium",
    isSelected,
    onToggleSelect,
    disabled = false,
    children,
    isLoading = false,
  } = props;

  const shortDate = formatShortDateWithWeekday(date);
  const dayName = formatDayName(date);
  const dayOfMonth = getDate(date);

  const {
    dayOfMonthVariant,
    showDayName,
    width,
    height,
    paddingTop,
    paddingBottom,
    showCloseButton,
  } = SIZE_CONFIGS[size];

  return (
    <ButtonBase
      aria-pressed={isSelected}
      aria-label={shortDate}
      disabled={disabled}
      sx={(theme) => ({
        alignItems: "center",
        border: `1px solid ${String(theme.palette.border?.primary)}`,
        borderRadius: theme.borderRadius?.small,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        height,
        justifyContent: "flex-start",
        paddingBottom,
        paddingTop,
        position: "relative",
        px: 2,
        transition: `${theme.transitions.create(["border", "background-color"], {
          duration: theme.transitions.duration.standard,
        })}`,
        userSelect: "none",
        width,

        "&:not(:disabled):hover, &:not(:disabled)[aria-pressed=true]": {
          border: `1px solid ${String(theme.palette.primary.main)}`,
        },

        "&:disabled": {
          color: String(theme.palette.disabled?.primary),
        },

        "& .MuiTypography-root": {
          color: disabled ? theme.palette.disabled?.primary : undefined,
          lineHeight: "1rem",
        },
      })}
      onClick={(event) => {
        if (!disabled && isDefined(onToggleSelect)) {
          onToggleSelect(!isSelected, event);
        }
      }}
    >
      {showDayName && <Text variant="body2">{dayName}</Text>}

      <Text variant={dayOfMonthVariant}>{dayOfMonth}</Text>

      {isLoading ? <Skeleton variant="circular" height="1rem" width="1rem" /> : children}

      {showCloseButton && !disabled && isSelected && (
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: "-0.375rem",
            right: "-0.375rem",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.invertPrimary,
            borderRadius: "50%",
            width: "1.25rem",
            height: "1.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <CbhIcon type="close" size="xSmall" />
        </Box>
      )}
    </ButtonBase>
  );
}
