import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ShiftDiscoveryCalendarPicker } from "../CalendarPicker/Picker";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "../paths";
import { ShiftDiscoveryListViewPage } from "./Page";

export function ShiftDiscoveryListViewContainer() {
  const location = useLocation();

  const calendarModalState = useModalState();

  useEffect(() => {
    // Modal has its unique url and we don't directly control its state from any other components
    calendarModalState.setModalStatus(
      location.pathname.endsWith(SHIFT_DISCOVERY_CALENDAR_PATH)
        ? ModalStatus.OPEN
        : ModalStatus.CLOSED
    );
  }, [calendarModalState, location.pathname]);

  return (
    <>
      <ShiftDiscoveryListViewPage />
      <ShiftDiscoveryCalendarPicker modalState={calendarModalState} />
    </>
  );
}
