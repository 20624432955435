import { Redirect, Route, Switch } from "react-router-dom";

import { RootPaths } from "../App/paths";
import { ShiftDiscoveryListViewContainer } from "./ListView/Container";
import { ShiftDiscoveryMapViewPage } from "./MapView/Page";
import { SHIFT_DISCOVERY_LIST_PATH, SHIFT_DISCOVERY_MAP_PATH } from "./paths";

export function ShiftDiscoveryRouter() {
  return (
    <Switch>
      <Route
        path={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`}
        component={ShiftDiscoveryListViewContainer}
      />
      <Route
        path={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}`}
        component={ShiftDiscoveryMapViewPage}
      />

      <Route
        render={() => <Redirect to={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`} />}
      />
    </Switch>
  );
}
