import { Text } from "@clipboard-health/ui-react";

import {
  CalendarGridSectionWrapper,
  type CalendarGridSectionWrapperProps,
} from "./GridSectionWrapper";

const DAYS_OF_WEEK = [
  { long: "Sunday", short: "S" },
  { long: "Monday", short: "M" },
  { long: "Tuesday", short: "T" },
  { long: "Wednesday", short: "W" },
  { long: "Thursday", short: "T" },
  { long: "Friday", short: "F" },
  { long: "Saturday", short: "S" },
];

interface CalendarHeaderRowProps extends CalendarGridSectionWrapperProps {}

export function CalendarHeaderRow(props: CalendarHeaderRowProps) {
  return (
    <CalendarGridSectionWrapper {...props}>
      {DAYS_OF_WEEK.map((day) => (
        <Text key={day.long} variant="body2" sx={{ textAlign: "center", py: 4 }}>
          {day.short}
        </Text>
      ))}
    </CalendarGridSectionWrapper>
  );
}
