import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { isPlatform } from "@ionic/react";
import { ThemeProvider } from "@mui/material";
import { useEffect } from "react";

import { PageWrapper } from "./components/PageWrapper";
import { ShiftDiscoveryCalendarDataProvider } from "./contexts/useShiftDiscoveryCalendarData";
import { ShiftDiscoveryListDataProvider } from "./contexts/useShiftDiscoveryListData";
import { Navbar } from "./Navbar";
import { ShiftDiscoveryRouter } from "./Router";
import { getShiftDiscoveryTheme } from "./theming/theme";

export function ShiftDiscoveryContainer() {
  // TODO: Remove this once we migrate to new shift discovery theme
  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return undefined;
    }

    void StatusBar.setStyle({
      style: StatusBarStyle.Light,
    });

    return () => {
      void StatusBar.setStyle({
        style: StatusBarStyle.Dark,
      });
    };
  }, []);

  return (
    <ThemeProvider theme={getShiftDiscoveryTheme()}>
      <ShiftDiscoveryCalendarDataProvider>
        <ShiftDiscoveryListDataProvider>
          <PageWrapper>
            <ShiftDiscoveryRouter />
            <Navbar />
          </PageWrapper>
        </ShiftDiscoveryListDataProvider>
      </ShiftDiscoveryCalendarDataProvider>
    </ThemeProvider>
  );
}
