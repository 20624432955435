import { Text, useModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useState } from "react";

import { useShiftDiscoveryListDataContext } from "../contexts/useShiftDiscoveryListData";
import { ShiftBottomSheet } from "../Shift/BottomSheet/Sheet";
import { ShiftCard } from "../Shift/Card/Card";
import { ShiftType } from "../Shift/constants";
import { type ShiftWithType } from "../Shift/types";
import { ShiftDiscoveryListViewHeader } from "./Header";

export function ShiftDiscoveryListViewPage() {
  const { openShiftsData, openShiftsIsLoading, openShiftsIsSuccess, openShiftsIsError } =
    useShiftDiscoveryListDataContext();

  const [selectedShift, setSelectedShift] = useState<ShiftWithType>();

  const modalState = useModalState();

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        overflow: "hidden",
      })}
    >
      <ShiftDiscoveryListViewHeader />

      <Stack spacing={5} sx={{ px: 5, py: 6, overflow: "auto" }}>
        {openShiftsIsError && <Text>Error loading shifts</Text>}
        {openShiftsIsLoading && <Text>Loading shifts...</Text>}
        {openShiftsIsSuccess &&
          openShiftsData?.openAgentShifts.map((shift) => (
            <ShiftCard
              key={shift._id}
              shift={{ ...shift, type: ShiftType.GENERAL }}
              onClick={() => {
                setSelectedShift({ ...shift, type: ShiftType.GENERAL });
                modalState.openModal();
              }}
            />
          ))}
      </Stack>

      {selectedShift && <ShiftBottomSheet shift={selectedShift} modalState={modalState} />}
    </Stack>
  );
}
