import { Text } from "@clipboard-health/ui-react";
import { type DateRange } from "@src/appV2/lib";
import { type ComponentProps } from "react";

import { DotSeparatedList } from "../components/DotSeparatedList";
import { formatDateRangeDuration } from "../utils/formatDateRangeDuration";
import { formatDayName } from "../utils/formatDayName";
import { formatDayOfMonth } from "../utils/formatDayOfMonth";

// TODO: Export TextProps from @clipboard-health/ui-react
export interface DateWithDurationProps extends ComponentProps<typeof Text> {
  dateRange: DateRange;
}

export function DateWithDuration(props: DateWithDurationProps) {
  const { dateRange, ...textProps } = props;
  const { startDate } = dateRange;

  return (
    <DotSeparatedList>
      <Text {...textProps}>{`${formatDayName(startDate)}, ${formatDayOfMonth(startDate)}`}</Text>
      <Text {...textProps}>{formatDateRangeDuration(dateRange)}</Text>
    </DotSeparatedList>
  );
}
