import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";

import { CalendarHeaderRow } from "../Calendar/HeaderRow";
import { CbhIcon } from "../components/CbhIcon";
import { LegacyOpenShiftTabSelector } from "./LegacyOpenShiftTabSelector";

export function ShiftDiscoveryCalendarPickerHeader() {
  return (
    <Box
      component="header"
      sx={(theme) => ({
        boxShadow: theme.shadows[3],
        bgcolor: theme.palette.background?.tertiary,
        display: "flex",
        flexDirection: "column",
      })}
    >
      <Stack
        spacing={4}
        sx={{
          // not using rem here as we don't want people with zoomed font to have header on half of their screen
          flex: "1 1 160px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Text variant="body2">Dates with dots have open shifts.</Text>

          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Text variant="body2">Your booked shifts are marked with a </Text>
            <Box sx={(theme) => ({ color: theme.palette.success.main })}>
              <CbhIcon type="checkmark" size="small" />
            </Box>
          </Stack>
        </Box>

        <LegacyOpenShiftTabSelector />
      </Stack>

      <CalendarHeaderRow sx={{ px: 5 }} />
    </Box>
  );
}
