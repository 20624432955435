import { Text } from "@clipboard-health/ui-react";
import { type DateRange } from "@src/appV2/lib/Calendar/types";
import { type ComponentProps } from "react";

import { formatTimeRange } from "../utils/formatTimeRange";

// TODO: Export TextProps from @clipboard-health/ui-react
export interface TimeRangeLabelProps extends ComponentProps<typeof Text> {
  dateRange: DateRange;
}

export function TimeRangeLabel(props: TimeRangeLabelProps) {
  const { dateRange, ...textProps } = props;

  return (
    <Text variant="body2" {...textProps}>
      {formatTimeRange(dateRange)}
    </Text>
  );
}
