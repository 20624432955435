import { Tab, Tabs } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useIsJobsEnabled } from "@src/appV2/PlacementCandidate/useIsJobsEnabled";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import { useHistory } from "react-router-dom";

/**
 * This component is supposed to be temporary.
 * It's used to render the old open shifts tab selector between per diem, home health, etc
 * I assume at later stages of the redesign, we will have a solution more aligned with the new design
 */
export function LegacyOpenShiftTabSelector() {
  const isHomeHealthEnabled = useIsHomeHealthEnabled();
  const isJobsEnabled = useIsJobsEnabled();
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });

  const history = useHistory();

  const shouldShowTabs =
    isHomeHealthEnabled || shiftBlockBookingFlag.isBlockBookingEnabled || isJobsEnabled;

  if (!shouldShowTabs) {
    return null;
  }

  return (
    <Tabs
      centered
      value="per-diem"
      variant="fullWidth"
      onChange={(_, value: string) => {
        if (value === "per-diem") {
          return;
        }

        history.push(`${DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}?shiftBookingTab=${value}`);
      }}
    >
      <Tab label="Per Diem" value={ShiftBookingType.PER_DIEM} />
      {shiftBlockBookingFlag.isBlockBookingEnabled && (
        <Tab label="Blocks" value={ShiftBookingType.BLOCK_BOOKING} />
      )}
      {isHomeHealthEnabled && <Tab label="Home Health" value={ShiftBookingType.HOME_HEALTH} />}
      {isJobsEnabled && <Tab label="Jobs" value={ShiftBookingType.JOBS} />}
    </Tabs>
  );
}
